export default {
  /**
   * Get all tenants in paginations
   * @param { limit: Number, offset: Number, access: String } params
   */
  getTenants(params) {
    return this.get('/oauth/mamipay/request/tenant/contract', { params });
  },
  getTenantDetails(contractId) {
    return this.get(`/oauth/mamipay/request/contract/${contractId}`);
  },
  getContractBills({ contractId, year }) {
    return this.get(
      `/oauth/mamipay/request/contract/${contractId}/payment-schedules/${year}`
    );
  },
  updateTenantContractPhoneNumber({ tenantId, phoneNumber }) {
    return this.put(`/oauth/mamipay/request/tenant/${tenantId}/phone-number`, {
      phone_number: phoneNumber,
    });
  },
  postTenantCheckinReminder(contractId) {
    return this.post(`/oauth/owner/booking/check-in/reminder/${contractId}`);
  },

  // Tenant Background Checker API
  getTenantBackgroundChecker(tenantId) {
    return this.get(`/pms_api/v3/tenant/${tenantId}/background`);
  },

  getTenantReportReason() {
    return this.get('/pms_api/v3/tenant/report-reason');
  },
  submitTenantReport(tenantId, params) {
    return this.post(`/pms_api/v3/tenant/${tenantId}/report`, params);
  },
};
