import dayjs from 'dayjs';
import 'dayjs/locale/id';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.locale('id');

export { dayjs as $dayjs };

export default (ctx, inject) => {
  window.dayjs = dayjs;
  inject('dayjs', dayjs);
};
