import Cookies from 'js-cookie';
import { getCurrentUTCTime } from '~/utils/datetime';

const authFlag = Cookies.get('auth_flag');
const lastLogin = localStorage.getItem('user_last_login');

const trackAuthenticatedUser = (userData, userLastLogin, instance) => {
  instance.$tracker.send('moe', [
    '[Web] Open App when Login',
    {
      timestamp: getCurrentUTCTime(),
      name: userData.name,
      phone_number: userData.phone_number,
      email: userData.email,
      last_login: userLastLogin,
      is_owner: true,
      device: window.innerWidth < 768 ? 'mobile' : 'desktop',
    },
  ]);
};

const removeLocalStorage = () => {
  localStorage.removeItem('user_last_login');
  localStorage.removeItem('mamiads/visibilityRedirectIfNoBalance');
  localStorage.removeItem('mamiads/visibilityRedirect');
  localStorage.removeItem('goldplus/lastSeenGpReminderDay');
};

export const initAuthTracker = (instance, userData) => {
  if (lastLogin) {
    if (!authFlag) {
      Cookies.set('auth_flag', 1, { expires: 1 });

      // Track auth user every time session expired.
      trackAuthenticatedUser(userData, lastLogin, instance);

      // Remove localStorage every time session expired
      localStorage.removeItem('isBroadcastChatOnboardPopup');
    }
  } else {
    if (!lastLogin) {
      localStorage.setItem('user_last_login', getCurrentUTCTime());

      // Track user's first time login at a time.
      trackAuthenticatedUser(userData, getCurrentUTCTime(), instance);

      // Remove localStorage first time login at a time.
      localStorage.removeItem('isBroadcastChatOnboardPopup');
    }

    if (!authFlag) {
      Cookies.set('auth_flag', 1, { expires: 1 });
    }
  }
};

export const clearAuthTracker = () => {
  // Remove tracking & localStorage items when user isn't in logged in states.
  Cookies.remove('auth_flag');
  removeLocalStorage();
};
